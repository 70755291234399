.App {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .head {
  text-transform: uppercase;
}

ul{
  list-style: none;
}

ul li, ul{
  margin: 0;
  padding: 0;
}

p {

}

h1, h2, h3 {
  text-align: left;
  font-family: 'Open Sans', sans-serif;;
}

h2, h3, h5 {
    text-align: left;
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
}

h5{
  font-weight: bold;
  margin-top: 2em;;
}

section{
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section h2{
  padding-bottom: 1.5rem;
  font-size: 1.9rem;
  text-align: left;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.m-card {
  box-shadow: 0 0 10px rgba(0,0,0,.1)
}

.btn-primary {
  border: 1px solid #3171e0;
  color: white;
  background: #3171e0;
}

.btn-primary:hover {
  border: 1px solid #3171e0;
  color: white;
  background: #3171e0;
}

/*
.btn-primary:hover, .btn-primary:active {
  border: 1px solid #333;
  background: #333;
  color: white;
}
*/

.btn-secondary {
  border: 1px solid #5a6268;
  color: #5a6268;
  background: white;
}

/*
.btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #5a6268;
  background: #5a6268;
  color: white;
}
*/

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?afo0h0');
  src:  url('/fonts/icomoon.eot?afo0h0#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?afo0h0') format('truetype'),
  url('/fonts/icomoon.woff?afo0h0') format('woff'),
  url('/fonts/icomoon.svg?afo0h0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_drop_down:before {
  content: "\e900";
}
.icon-keyboard_arrow_down:before {
  content: "\e901";
}

strong {
    font-family: 'Roboto', sans-serif;;
    font-weight: 500;
}

/* MOBILE */

@media (max-width: 991.98px) {
  section {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  section h2 {
    text-align: left;
  }

  section img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

a {
  color: #005eec;
}

a:hover {
  color: #005eec;
}

.btn-primary {
  background-color: #005eec;
  color: white;
  outline: 0;
  border: 1px solid #005eec;
}

/*
.btn-primary:hover, .btn-primary:active {
  background-color: rgba(246, 30, 35, 0.1);
  color: #3171e0;
  outline: 0;
  border: 1px solid #3171e0;
}
*/

.btn-secondary {
  border: 1px solid #005eec;
  color: #005eec;
  background: white;
}

.btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #005eec;
  color: #005eec;
  background-color: white;
}

.btn {
    font-weight: 300;
}