footer {
  background: #03050C;
  color: #D8DFE9;
}

footer .col-right{
  text-align: right;
}

footer .logos img {
  max-height: 100px;
  margin-bottom: 1em;
  margin-right: 1em;
}

footer .logos a:last-child img{
  background: white;
  padding: .5em 2em .5em 2em;
}

footer .credits .other-info{
  text-align: center;
  font-size: 10pt;
}

footer .credits .bold {
  font-weight: bold;
}

footer a:link, footer a:visited {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
  color: #f71d26;
}

footer a:hover, footer a:active {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
  color: #f71d26;
  text-decoration: none;
}

footer .credits p {
  text-align: center;
}

footer .credits p.legal {
  padding-top: 30px;
  font-weight: bold;
  font-size: 12pt;
  line-height: 11px;
}

footer .credits p.piva {
  font-size: 12pt;
  line-height: 2px;
}

footer .credits p.link {
  font-size: 12pt;
  font-weight: bold;
  line-height: 48px;
}

@media (max-width: 767.98px) {
  footer .col-right{
    text-align: left;
  }

  footer .credits p.legal {
    line-height: 25px;
  }

  footer .credits p.piva {
    line-height: 25px;
  }
}