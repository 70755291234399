section.section-image-text {
  height: 100%;
  min-height: 100%;
}

section.section-image-text h2 {
  /*text-transform: uppercase;*/
}

section.section-image-text .order{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

section.section-image-text .right{
  flex-direction: row-reverse;
}

section.section-image-text .limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text .order img{
  max-width: 60%;
  max-height: 60vh;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

section.section-image-text .order .text{
  flex: 1;
  max-width: 40%;
  text-align: left;
}

section.section-image-text .left h2,
section.section-image-text .left p
{
  padding-left: 0;
  padding-right: 5%;
}

section.section-image-text .right h2,
section.section-image-text .right p {
  padding-left: 5%;
  padding-right: 0;
}

section.section-image-text p span {
  display: block;
  margin-bottom: 10px;
}


section.section-image-text .phone-img{
  max-height: 450px;
  margin: 0 auto;
  display: block;
}