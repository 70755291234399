.project-name-logo{
  padding: .6rem 2rem;
  font-size: 2.5rem;
  color: white;
  background-color: #005eec;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.claim-content .main-content{
  margin: 0 auto;
}

.claim-content .main-content h1{
  margin-bottom: 2rem;
  text-rendering: optimizeSpeed;
}

.claim-content .head{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.2rem;
  color: white;
  line-height: .5rem;
}

.claim-content .head a span.claim-head{
  color: #999999;
  font-size: .9rem;
  position: absolute;
  top: 25px;
  left: 44px;
}

.claim-content .head a:hover span {
  color: #005eec;
  text-decoration: none;
}

.claim-content .head a .icon-atleticame .path1:before {
  content: "\e902";
  color: #999;
}

.claim-content .head a .icon-atleticame .path2:before {
  content: "\e903";
  color: #fff;
  margin-left: -1em;
}

.claim-content .head a:hover .icon-atleticame .path1:before {
  content: "\e902";
  color: #005eec;
}
a:hover {
  text-decoration: none;
}

.claim-content .btn-container {
  display: block;
}

.claim-content .btn-container .btn {
  min-width: 300px;
}

.claim-content h1 span {
  font-weight: bold;
}

.claim-content .color-1 {
    color: #005eec;
}

.claim-content .color-2 {
    color: #cdddfc;
}

.claim-content .logo-callroom {
    max-width: 120px;
}

/* MOBILE */

@media (max-width: 991.98px) {
  .claim-content {
    padding-top: 30px;
  }

  .claim-content .btn-container .btn {
    min-width: auto;
    width: 100%;
  }
}
