body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.font-weight-bold {
    font-family: 'Roboto', sans-serif;;
    font-weight: 500;
}
.fill-img{
    width: 100%;
}

.project-name-logo{
  padding: .6rem 2rem;
  font-size: 2.5rem;
  color: white;
  background-color: #005eec;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.claim-content .main-content{
  margin: 0 auto;
}

.claim-content .main-content h1{
  margin-bottom: 2rem;
  text-rendering: optimizeSpeed;
}

.claim-content .head{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.2rem;
  color: white;
  line-height: .5rem;
}

.claim-content .head a span.claim-head{
  color: #999999;
  font-size: .9rem;
  position: absolute;
  top: 25px;
  left: 44px;
}

.claim-content .head a:hover span {
  color: #005eec;
  text-decoration: none;
}

.claim-content .head a .icon-atleticame .path1:before {
  content: "\E902";
  color: #999;
}

.claim-content .head a .icon-atleticame .path2:before {
  content: "\E903";
  color: #fff;
  margin-left: -1em;
}

.claim-content .head a:hover .icon-atleticame .path1:before {
  content: "\E902";
  color: #005eec;
}
a:hover {
  text-decoration: none;
}

.claim-content .btn-container {
  display: block;
}

.claim-content .btn-container .btn {
  min-width: 300px;
}

.claim-content h1 span {
  font-weight: bold;
}

.claim-content .color-1 {
    color: #005eec;
}

.claim-content .color-2 {
    color: #cdddfc;
}

.claim-content .logo-callroom {
    max-width: 120px;
}

/* MOBILE */

@media (max-width: 991.98px) {
  .claim-content {
    padding-top: 30px;
  }

  .claim-content .btn-container .btn {
    min-width: auto;
    width: 100%;
  }
}

section.section-text {
  text-align: center;
  color: black;
  /*padding-top: 16vh;*/
  /*padding-bottom: 16vh;*/
}

section.section-text .limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text {
  height: 100%;
  min-height: 100%;
}

section.section-image-text h2 {
  /*text-transform: uppercase;*/
}

section.section-image-text .order{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

section.section-image-text .right{
  flex-direction: row-reverse;
}

section.section-image-text .limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text .order img{
  max-width: 60%;
  max-height: 60vh;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

section.section-image-text .order .text{
  flex: 1 1;
  max-width: 40%;
  text-align: left;
}

section.section-image-text .left h2,
section.section-image-text .left p
{
  padding-left: 0;
  padding-right: 5%;
}

section.section-image-text .right h2,
section.section-image-text .right p {
  padding-left: 5%;
  padding-right: 0;
}

section.section-image-text p span {
  display: block;
  margin-bottom: 10px;
}


section.section-image-text .phone-img{
  max-height: 450px;
  margin: 0 auto;
  display: block;
}
.simple-photo img {
	max-height: 100px;
	display: block;
	margin: 0 auto;
}

.simple-photo a {
	color: black;
}

.simple-photo p {
	text-align: center;
}
.image-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
section.section-revolution {
  height: 100%;
  min-height: 100%;
}

section.section-revolution h2 {
  text-align: center;
}

section.section-revolution p.clubs-name {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  position: relative;
}

section.section-revolution p small {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
}

section.section-revolution .img-container {
  height: 80px;
}

section.section-responsive {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.responsive {
  margin: 0 auto;
  display: block;
}

footer {
  background: #03050C;
  color: #D8DFE9;
}

footer .col-right{
  text-align: right;
}

footer .logos img {
  max-height: 100px;
  margin-bottom: 1em;
  margin-right: 1em;
}

footer .logos a:last-child img{
  background: white;
  padding: .5em 2em .5em 2em;
}

footer .credits .other-info{
  text-align: center;
  font-size: 10pt;
}

footer .credits .bold {
  font-weight: bold;
}

footer a:link, footer a:visited {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
  color: #f71d26;
}

footer a:hover, footer a:active {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
  color: #f71d26;
  text-decoration: none;
}

footer .credits p {
  text-align: center;
}

footer .credits p.legal {
  padding-top: 30px;
  font-weight: bold;
  font-size: 12pt;
  line-height: 11px;
}

footer .credits p.piva {
  font-size: 12pt;
  line-height: 2px;
}

footer .credits p.link {
  font-size: 12pt;
  font-weight: bold;
  line-height: 48px;
}

@media (max-width: 767.98px) {
  footer .col-right{
    text-align: left;
  }

  footer .credits p.legal {
    line-height: 25px;
  }

  footer .credits p.piva {
    line-height: 25px;
  }
}
.App {
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .head {
  text-transform: uppercase;
}

ul{
  list-style: none;
}

ul li, ul{
  margin: 0;
  padding: 0;
}

p {

}

h1, h2, h3 {
  text-align: left;
  font-family: 'Open Sans', sans-serif;;
}

h2, h3, h5 {
    text-align: left;
    font-family: 'Roboto', sans-serif;;
    font-weight: 300;
}

h5{
  font-weight: bold;
  margin-top: 2em;;
}

section{
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section h2{
  padding-bottom: 1.5rem;
  font-size: 1.9rem;
  text-align: left;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.m-card {
  box-shadow: 0 0 10px rgba(0,0,0,.1)
}

.btn-primary {
  border: 1px solid #3171e0;
  color: white;
  background: #3171e0;
}

.btn-primary:hover {
  border: 1px solid #3171e0;
  color: white;
  background: #3171e0;
}

/*
.btn-primary:hover, .btn-primary:active {
  border: 1px solid #333;
  background: #333;
  color: white;
}
*/

.btn-secondary {
  border: 1px solid #5a6268;
  color: #5a6268;
  background: white;
}

/*
.btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #5a6268;
  background: #5a6268;
  color: white;
}
*/

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?afo0h0');
  src:  url('/fonts/icomoon.eot?afo0h0#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?afo0h0') format('truetype'),
  url('/fonts/icomoon.woff?afo0h0') format('woff'),
  url('/fonts/icomoon.svg?afo0h0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_drop_down:before {
  content: "\E900";
}
.icon-keyboard_arrow_down:before {
  content: "\E901";
}

strong {
    font-family: 'Roboto', sans-serif;;
    font-weight: 500;
}

/* MOBILE */

@media (max-width: 991.98px) {
  section {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  section h2 {
    text-align: left;
  }

  section img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

a {
  color: #005eec;
}

a:hover {
  color: #005eec;
}

.btn-primary {
  background-color: #005eec;
  color: white;
  outline: 0;
  border: 1px solid #005eec;
}

/*
.btn-primary:hover, .btn-primary:active {
  background-color: rgba(246, 30, 35, 0.1);
  color: #3171e0;
  outline: 0;
  border: 1px solid #3171e0;
}
*/

.btn-secondary {
  border: 1px solid #005eec;
  color: #005eec;
  background: white;
}

.btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #005eec;
  color: #005eec;
  background-color: white;
}

.btn {
    font-weight: 300;
}
